import React from 'react';
import Unsubscribe from './Unsubscribe'; // Import the Unsubscribe component

function App() {
  return (
    <div className="app-container">
      <Unsubscribe />
    </div>
  );
}

export default App;
