import React, { useState } from 'react';
import axios from 'axios';
import './Unsubscribe.css';
import logo from './logo.png'; // Ensure you have a logo.png file in your project

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleUnsubscribe = () => {
    if (email) {
      axios.post('https://unsubscribe.marriedtothemopnewsletter.online/unsubscribe', { email })
      //axios.post('http://localhost:3004/unsubscribe', { email })
        .then(response => {
          setMessage(response.data.message);
        })
        .catch(error => {
          console.error('There was an error!', error);
          setMessage('Failed to unsubscribe');
        });
    } else {
      setMessage('Please enter an email address');
    }
  };

  return (
    <div className="unsubscribe-container">
      <img src={logo} alt="Logo" className="logo" />
      <h1>Unsubscribe</h1>
      <p>We are sorry to see you going!</p>
      <p>              .               </p>
      <div className="form-group">
        <label>Enter email address:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control"
        />
      </div>
      <button onClick={handleUnsubscribe} className="btn btn-primary">Unsubscribe</button>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default Unsubscribe;
